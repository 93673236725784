import React from 'react';
import Helmet from 'react-helmet';
import Offerings from '../Offerings';
import Testimonials from '../Testimonials';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import 'swiper/css/swiper.min.css';
import { cloudinaryTransforms } from '../../utils/cloudinaryTransforms';

const HomePageTemplate = ({
  title,
  heading,
  hero_image,
  offerings,
  meta_title,
  meta_description,
  testimonials,
}) => {
  let heroImage = cloudinaryTransforms(hero_image, 'c_limit,w_2000,q_100');
  return (
    <div>
      <Helmet>
        <title>{meta_title}</title>
        <meta name="description" content={meta_description} />
      </Helmet>
      <section
        className="hero is-primary is-bold is-medium"
        style={{
          backgroundImage: `linear-gradient(black, black), url('${heroImage}')`,
        }}
      >
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="hero-text">
                  <h1 className="title">{title}</h1>
                  <Link to="/contact" className="button is-primary">
                    Request Service
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section section--gradient">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">
                <div>
                  <h3 className="has-text-weight-semibold is-size-2 has-text-centered">
                    {heading}
                  </h3>
                </div>
                <Offerings gridItems={offerings} />
                <h2 className="has-text-weight-semibold is-size-2">
                  Testimonials
                </h2>
                <Testimonials testimonials={testimonials} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

HomePageTemplate.propTypes = {
  title: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  heading: PropTypes.string,
  offerings: PropTypes.array,
  testimonials: PropTypes.array,
};

export default HomePageTemplate;
