import React from 'react';
import PropTypes from 'prop-types';
import Swiper from 'swiper';
import { cloudinaryTransforms } from '../../utils/cloudinaryTransforms';

const Offerings = ({ gridItems }) => {
  React.useLayoutEffect(() => {
    new Swiper('.swiper-container', {
      loop: true,
      loopFillGroupWithBlank: true,
      autoHeight: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      slidesPerView: 1,
      spaceBetween: 20,
      centeredSlides: true,
      breakpoints: {
        840: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
      },
    });
  }, []);
  return (
    <div className="swiper-container">
      <div className="swiper-wrapper" style={{ paddingBottom: '20px' }}>
        {gridItems.map(item => {
          let image = cloudinaryTransforms(
            item.image,
            'c_fit,w_546,h_409,q_80'
          );
          return (
            <div
              key={item.image}
              className="swiper-slide"
              style={{ borderRadius: '5px', height: '100%' }}
            >
              <section className="card" style={{ height: '100%' }}>
                <div className="card-image">
                  <figure className="image is-4by3" style={{ margin: 0 }}>
                    <img alt="" src={image} />
                  </figure>
                </div>
                <div className="card-header">
                  <p className="card-header-title">{item.title}</p>
                </div>
                <div className="card-content">
                  <p>{item.text}</p>
                </div>
              </section>
            </div>
          );
        })}
      </div>

      <div className="swiper-button-prev" style={{ color: '#cc3b49' }} />
      <div className="swiper-button-next" style={{ color: '#cc3b49' }} />
    </div>
  );
};

Offerings.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      text: PropTypes.string,
    })
  ),
};

export default Offerings;
